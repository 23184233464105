<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 class="text-3xl font-bold tracking-tight text-gray-900">Can't find what you're looking for?</h2>
          <p class="mt-4 text-lg text-gray-500">Reach out to our <a href="mailto:support@complaia.com" class="font-medium text-indigo-600 hover:text-indigo-500">customer support</a> team.</p>
        </div>
        <div class="mt-12 lg:col-span-2 lg:mt-0">
          <dl class="space-y-12">
            <!-- <div v-for="faq in faqs" :key="faq.question">
              <dt class="text-lg font-medium leading-6 text-gray-900">{{ faq.question }}</dt>
              <dd class="mt-2 text-base text-gray-500">{{ faq.answer }}</dd>
            </div> -->
            <div>
              <dd class="mt-2 text-base text-gray-500">
                This Privacy Policy describes how your personal information is collected, used, and shared when you visit https://complaia.com (the “Site”). 
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Protecting your private information is our priority. This Statement of Privacy applies to https://complaia.com and governs data collection and usage. We have developed this policy in order for you to understand how we collect, use, communicate and make use of personal information. The following outlines our privacy policy. 
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Similar to other commercial websites, our website utilizes a standard technology called ‘cookies’ (see our cookies policy section for more information) and server logs to collect information about how our site is used. Information gathered through cookies and server logs may include the date and time of visits, the pages viewed, time spent at our site, and the websites visited just before and just after our own, as well as your IP address. 
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                INTRODUCTION
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                This Policy sets out the conditions under which we may process any information that we collect from you, or that you provide to us. It covers information that could identify you (“personal information”) and information that could not. In the context of the law and this notice, “process” means collect, store, transfer, use or otherwise act on information.
                <ol class="list-decimal list-inside mt-4">
                  <li>We take seriously the protection of your privacy and confidentiality. We understand that all visitors to our website are entitled to know that their personal data will not be used for any purpose unintended by them, and will not accidentally fall into the hands of a third party. </li>
                  <li>COMPLAIA SYSTEMS SP. Z O.O. undertakes to preserve the confidentiality of all information you provide to us, and hope that you reciprocate.</li>
                  <li>The law requires us to tell you about your rights and our obligations to you in regards to the processing and control of your personal data.</li>
                </ol>
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                LOG FILES
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                COMPLAIA SYSTEMS SP. Z O.O. follows a standard procedure of using log files. These files, log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected from log files includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analysing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                COLLECTION OF YOUR PERSONAL INFORMATION
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                COMPLAIA SYSTEMS SP. Z O.O. may collect personally identifiable information such as your: Name, Phone Number and E-mail Address from our website. COMPLAIA SYSTEMS SP. Z O.O. encourages you to review the privacy statements of websites you choose to link to from COMPLAIA SYSTEMS SP. Z O.O. so that you can understand how those websites collect, use and share your information. We are not responsible for the privacy statements or other content on websites outside of the <span class="font-bold">COMPLAIA SYSTEMS SP. Z O.O.</span> website.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                WHAT INFORMATION DO WE COLLECT AND WHY?
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                We may collect information about you directly from you, as well as automatically through your use of our Site or Services. 
                <ul class="list-disc list-inside mt-4">
                  <li><span class="font-bold">Information We Collect Directly from You:</span> Certain areas and features of our Site and Services require your Name, email address, and IP address.</li>
                  <li><span class="font-bold">Information We Collect Automatically:</span> We may automatically collect the following information about your use of our Site via some software analytics including the length of time you visit our Site and your movement through our Site.</li>
                  <li><span class="font-bold">We may also collect the following information:</span> device ID; location and language information.</li>
                </ul>
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                WE DO NOT SELL, RENT OR LEASE ITS CUSTOMER LISTS TO THIRD PARTIES.
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                COMPLAIA SYSTEMS SP. Z O.O. may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support. All such third parties are prohibited from using your personal information except to provide these services to COMPLAIA SYSTEMS SP. Z O.O., and they are required to maintain the confidentiality of your information.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                COMPLAIA SYSTEMS SP. Z O.O. will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) Conform to the edicts of the law or comply with legal process served on COMPLAIA SYSTEMS SP. Z O.O. or the site; (b) protect and defend the rights or property of COMPLAIA SYSTEMS SP. Z O.O.; And, (c) act under exigent circumstances to protect the personal safety of users of COMPLAIA SYSTEMS SP. Z O.O., or the public.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                OUR ADVERTISING PARTNERS
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Some of the advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                INFORMATION WE PROCESS BECAUSE WE HAVE A CONTRACTUAL OBLIGATION WITH YOU
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                When you use the <span class="font-bold">COMPLAIA SYSTEMS SP. Z O.O.</span> website, you agree to our terms and conditions, a contract is formed between you and us.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                In order to carry out our obligations under that contract we must process the information you give us. Some of this information may be personal information.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                We may use it in order to:
                <ul class="list-decimal list-inside mt-2">
                  <li>Verify your identity for security purposes</li>
                  <li>Provide you with our services</li>
                  <li>Provide you with suggestions and advice on services and how to obtain the most from using our website.</li>
                </ul>
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                COMPLAIA SYSTEMS SP. Z O.O. may aggregate this information in a general way and use it to provide class information, for example to monitor our performance with respect to a particular service we provide. If we use it for this purpose, you as an individual will not be personally identifiable. <span class="font-bold">COMPLAIA SYSTEMS SP. Z O.O.</span> shall continue to process this information until the contract between us ends or is terminated by either party under the terms of the contract.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Except where you have consented to our use of your information for a specific purpose, we do not use your information in any way that would identify you personally. COMPLAIA SYSTEMS SP. Z O.O. may aggregate it in a general way and use it to provide class information, for example to monitor the performance of a particular page on our website.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                You may withdraw your consent at any time by instructing us (hello@complaia.com) However, if you do so, you may not be able to use our website or our services further
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                RETENTION PERIOD FOR PERSONAL DATA
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Except as otherwise mentioned in this privacy notice, <span class="font-bold">COMPLAIA SYSTEMS SP. Z O.O.</span> keeps your personal information only for as long as required by us:
                <ul class="list-decimal list-inside mt-2">
                  <li>To provide you with the services you have requested;</li>
                  <li>To comply with other law, including for the period demanded by our tax authorities;</li>
                  <li>To support a claim or defense in court.</li>
                </ul>
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                COOKIES AND WEB BEACONS
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Like any other website, COMPLAIA SYSTEMS SP. Z O.O. uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                For more general information on cookies, please read "What Are Cookies" from Cookie Consent.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                THIRD PARTY PRIVACY POLICIES
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                COMPLAIA SYSTEMS SP. Z O.O. Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                CHILDREN'S INFORMATION
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                COMPLAIA SYSTEMS SP. Z O.O. does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                OPT-IN, OPT-OUT & UNSUBSCRIBE
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                When any user provides their details such as Name, Email, Phone number, accept T&C, Cookies as well as Privacy Policy, they have to OPTED-IN.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                We provide users the opportunity to opt-out of receiving communications from us by reading the unsubscribe instructions located at the bottom of any e-mail they receive from us at any time or email us.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain information. Users may opt-out of receiving any or all communications from COMPLAIA SYSTEMS SP. Z O.O. by contacting us here:
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Web page: https://complaia.com
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Email:  hello@complaia.com
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                INTELLECTUAL PROPERTY RIGHTS
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                All copyrights, trademarks, patents and other intellectual property rights in and on our website and all content and software located on the site shall remain the sole property of COMPLAIA SYSTEMS SP. Z O.O. or its licensors. The use of our trademarks, content and intellectual property is forbidden without the express written consent from us.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                You must not:
                <ol class="list-disc list-inside mt-2">
                  <li>Republish material from our website without prior written consent.</li>
                  <li>Sell or rent material from our website.</li>
                  <li>Reproduce, duplicate, create derivative, copy or otherwise exploit material on our website for any purpose.</li>
                  <li>Redistribute any content from our website, including onto another website.</li>
                </ol>
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                ACCEPTABLE USE
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                You agree to use our website only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else’s use and enjoyment of the website. Prohibited behaviour includes harassing or causing distress or inconvenience to any other user within our website. You must not use our website to send unsolicited commercial communications. You must not use the content on our website for any marketing related purpose without our express written consent.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                THIRD PARTY ANALYTICS
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                We use automated devices and applications, to evaluate usage of our Site and, to the extent permitted, our Services. We also may use other analytic means to evaluate our Services. We use these tools to help us improve our Services, performance and user experiences. These entities may use tracking technologies to perform their services.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                VERIFICATION OF YOUR INFORMATION
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                When we receive any request to access, edit or delete personal identifiable information, we shall first take reasonable steps to verify your identity before granting you access or otherwise taking any action. This is important to safeguard your information.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                ENCRYPTION OF DATA SENT BETWEEN US
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                COMPLAIA SYSTEMS SP. Z O.O. website use Secure Sockets Layer (SSL) certificates to verify our identity to your browser and to encrypt any data you give us. Whenever information is transferred between us, you can check that it is done so using SSL by looking for a closed padlock symbol or other trust mark in your browser’s URL bar or toolbar.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                HOW YOU CAN COMPLAIN
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                If you are not happy with our privacy policy or if any complaints, then you should tell us by email. Our e-mail address is hello@complaia.com
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                REVIEW/MODIFICATION OF THIS PRIVACY POLICY
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                COMPLAIA SYSTEMS SP. Z O.O. may update this privacy notice from time to time as necessary. The terms that apply to you are those posted here on our website on the day you use our website. We solely advise you to print a copy for your records.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                If you have any questions regarding our privacy policy, please contact us through:
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                E-mail:  Hello@complaia.com
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                COOKIES POLICY
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                The <span class="font-bold">COMPLAIA SYSTEMS SP. Z O.O.</span> website uses cookies. They are placed by the software that operates on our servers, and by software operated by third parties whose services we use. When you first visit our website, we ask you whether you wish us to use cookies. If you choose not to accept them, we shall not use them for your visit except to record that you have not consented to their use for any other purpose.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                If you choose not to use cookies or you prevent their use through your browser settings, you will not be able to use all the functionality of our website.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                We use cookies in the following ways:
                <ol class="list-disc list-inside mt-2">
                  <li>To track how you use our website,</li>
                  <li>To record whether you have seen specific messages we display on our website,</li>
                  <li>To keep you signed in our site,</li>
                  <li>To record your answers to surveys and questionnaires on our site while you complete them,</li>
                  <li>To record the conversation thread during a live chat with our support team</li>
                </ol>
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                WHY WE USE COOKIES ON OUR WEBSITE
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Cookies are pieces of information that a website transfers to an individual’s computer hard drive for record keeping purposes. Cookies make using our Site easier by, among other things, saving your passwords and preferences for you. These cookies are restricted for use only on our Site, and do not transfer any personal information to any other party.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Most browsers are initially set up to accept cookies. You can, however, reset your browser to refuse all cookies or indicate when a cookie is being sent. Please consult the technical information relevant to your browser for instructions. If you choose to disable your cookies setting or refuse to accept a cookie, some parts of the Site may not function properly or may be considerably slower.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                PERSONAL IDENTIFIERS FROM YOUR BROWSING ACTIVITY
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Information about your computer hardware and software may be automatically collected by COMPLAIA SYSTEMS SP. Z O.O.. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the <span class="font-bold"> COMPLAIA SYSTEMS SP. Z O.O. </span>website.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Requests by your web browser to our servers for web pages and other content on our website are recorded.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Our website record information such as your geographical location, your Internet service provider and your IP address. We also record information about the software you are using to browse our website, such as the type of computer or device and the screen resolution.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                COMPLAIA SYSTEMS SP. Z O.O. uses this information in aggregate to assess the popularity of the webpages on our website and how we perform in providing content to you.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                USE OF RE-MARKETING
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Re-marketing involves placing a cookie on your computer when you browse our website in order to be able to serve to you an advert for our services when you visit some other website.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                <span class="font-bold">COMPLAIA SYSTEMS SP. Z O.O. </span> may use a third party to provide us with re-marketing services from time to time. If so, then if you have consented to our use of cookies, you may see advertisements for our services on other websites.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
               <span class="font-bold"> CONTACT INFORMATION </span><br>
                If you would like to: access, correct, register a complaint, or simply want more information please contact us directly Via:
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Contact: hello@complaia.com
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}

</script>

